<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #e53935">ข้อมูลสินค้า</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-4"><b>สถานะ</b></span>
          </v-col>
          <v-col cols="12" md="6" sm="6" class="">
            <v-switch v-model="activeFlag" label="ใช้งาน" class="mt-0"></v-switch>
          </v-col>
        </v-row>
        <v-row align="top" justify="center">
          <v-col cols="12" md="3" sm="3"
            ><span><b>เลือกหมวดหมู่ของสินค้า</b></span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <span>{{ category.category }}</span>
          </v-col>
          <v-col cols="12" md="3" sm="3"
            ><span><b>เลือกประเภทของสินค้า</b></span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <span>{{ type.type }}</span>
          </v-col>
          <v-col cols="12" md="3" sm="3"
            ><span><b>เลือกยี่ห้อของสินค้า</b></span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <span>{{ brand.brandName }}</span>
          </v-col>
          <v-col cols="12" md="3" sm="3"><span><b>รหัสสินค้า</b></span> </v-col>
          <v-col cols="12" md="9" sm="9">
            <span>{{ sku }}</span>
          </v-col>
          <v-col cols="12" md="3" sm="3"><span><b>ชื่อสินค้า</b></span> </v-col>
          <v-col cols="12" md="9" sm="9">
            <span>{{ productName }}</span>
          </v-col>
          <v-col cols="12" md="3" sm="3"><span><b>รายละเอียดแบบย่อ</b></span> </v-col>
          <v-col cols="12" md="9" sm="9">
            <span >
              {{ shortDesc }}
              </span>
          </v-col>
          <v-col cols="12" md="3" sm="3"><span><b>รายละเอียด</b></span> </v-col>
          <v-col cols="12" md="9" sm="9">
            <span v-html="description">
              <!-- {{ description }} -->
              </span>
          </v-col>
          <v-col cols="12" md="3" sm="3"><span><b>เงื่อนไขการผ่อน</b></span> </v-col>
          <v-col cols="12" md="9" sm="9">
            <span >
              {{ termDesc }}
              </span>
          </v-col>
          <v-col cols="12" md="3" sm="3"><span><b>จำนวนสินค้า</b></span></v-col>
          <v-col cols="12" md="9" sm="9">
            <span>{{ stockAmt }} ชิ้น</span>
          </v-col>
          <v-col cols="12" md="3" sm="3"><span><b>หน่วยสินค้า</b></span></v-col>
          <v-col cols="12" md="9" sm="9">
            <span>{{ unit }} ชิ้น</span>
          </v-col>
          <v-col cols="12" md="2" sm="2"><span><b>ราคาขาย</b></span> </v-col>
          <v-col cols="12" md="4" sm="4">
            <span>{{ price }} บาท</span>
          </v-col>
          <v-col cols="12" md="2" sm="2"><span><b>ราคาต้นทุน</b></span> </v-col>
          <v-col cols="12" md="4" sm="4"
            ><span>{{ cost }} บาท</span></v-col
          >
          <v-col cols="12" md="2" sm="2"><span><b>ค่าจัดส่ง</b></span> </v-col>
          <v-col cols="12" md="4" sm="4">
            <span>{{ deliveryFee }} บาท</span>
          </v-col>

          <v-col cols="12" md="2" sm="2"><span><b>ค่าติดตั้ง</b></span> </v-col>
          <v-col cols="12" md="4" sm="4">
            <span>{{ setupFee }} บาท</span>
          </v-col>
          <v-col cols="12" md="2" sm="2"><span><b>แสดงในหน้าหลัก</b></span> </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-checkbox v-model="isHilight" disabled class="pt-0 mt-0"></v-checkbox>
          </v-col>
          <v-col cols="12" md="2" sm="2"><span><b>โชว์ลำดับที่</b></span> </v-col>
          <v-col cols="12" md="4" sm="4">
            <span>{{ showIndex }}</span>
          </v-col>
          <v-col
            cols="12"
            md="12"
            sm="12"
            xs="12"
            v-if="productOptionList.length > 0"
          >
            <h3 style="font-weight: 800; color: #e53935">ตัวเลือกทั้งหมด</h3>
            <v-card outlined class="px-2">
              <v-row
                v-for="(n, index) in productOptionList"
                :key="index"
                align="center"
                class="py-0 my-0"
              >
                <v-col cols="12" md="6" sm="12"
                  ><span
                    ><b>ตัวเลือก:</b>
                    {{ productOptionList[index].optionName }}
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="12"
            sm="12"
            xs="12"
            v-if="productPriceList.length > 0"
          >
            <h3 style="font-weight: 800; color: #e53935">ราคา EPP</h3>
            <v-card outlined class="px-2">
              <v-row
                v-for="(n, index) in productPriceList"
                :key="index"
                align="center"
                class="py-0 my-0"
              >
                <v-col cols="12" md="6" sm="12"
                  ><span
                    ><b>ชื่อบริษัท:</b>
                    {{ productPriceList[index].company.companyName }}
                  </span>
                </v-col>
                <v-col cols="12" md="6" sm="12"
                  ><span><b>ราคาบริษัท:</b> {{ productPriceList[index].price }} บาท</span>
                </v-col>
                <!-- <v-col cols="12" md="8" sm="12"
                ><span>รายละเอียด</span>
                <v-text-field
                  v-model="productPriceList[index].listDescription"
                  placeholder="รายละเอียด"
                  solo
                  dense
                ></v-text-field>
              </v-col> -->
              </v-row>
              <!-- <v-col cols="12" md="12" class="mb-4">
                <v-row align="center" justify="end">
                  <v-btn
                    v-if="productPriceList.length > 1"
                    @click="deleteRow1()"
                    dense
                    color="red"
                    class="mr-4"
                    dark
                    rounded
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <v-btn @click="addRow1()" dense color="green" dark rounded>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-row>
              </v-col> -->
            </v-card>
          </v-col>
          <v-col cols="12" md="12" sm="6" xs="12">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col cols="11" class="mt-5 ma-10" id="step-3">
                  <span>รูปสินค้าหลัก</span>

                  <v-img :src="showImage" width="200px"></v-img>
                  <v-text-field
                    v-model="picture_1"
                    @click="changePic1()"
                    :placeholder="imageName1"
                    prepend-icon="mdi-camera"
                    readonly
                  >
                  </v-text-field
                  ><input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/png"
                    @change="showPicture1($event)"
                    style="display: none"
                /></v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="12" class="mt-5">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col cols="11" class="mt-5 ma-10" id="step-3">
                  <span>รูปภาพสินค้าเพิ่มเติม</span>
                </v-col>
                <v-col cols="12" class="mb-5"><v-divider></v-divider></v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="pl-3 mb-5"
                  @click="onPickFile()"
                >
                  <v-row no-gutters align="center" justify="center">
                    <v-file-input
                      v-model="DataImage"
                      :items="DataImage"
                      accept="image/jpeg, image/jpg, image/png"
                      @change="UploadImage()"
                      id="file_input"
                      multiple
                      :clearable="false"
                      style="display: none"
                    ></v-file-input>
                    <v-img
                      max-width="50"
                      src="@/assets/upload.png"
                      class="mr-3"
                    ></v-img>
                    <span>เลือกรูปภาพ</span>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  v-if="product_image.length !== 0"
                  class="mb-5"
                >
                  <draggable
                    v-model="product_image"
                    :move="onMove"
                    @start="drag = true"
                    @end="drag = false"
                    class="pl-5 pr-5 row fill-height align-center sortable-list"
                  >
                    <v-col
                      v-for="(item, index) in product_image"
                      :key="index"
                      cols="3"
                      md="3"
                    >
                      <v-card dense light class="pa-1">
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-icon small light @click="RemoveImage(index, item)"
                            >mdi-close</v-icon
                          >
                        </v-card-actions>
                        <!-- <img width="100%"  :src="item.url" v-if="MethodProduct === 'Create'" />
                              <img width="100%" :src="`${PathImage}${item.url}`" v-else /> -->
                        <v-img
                          :src="item.productImgUrl"
                          aspect-ratio="1.8"
                          contain
                        ></v-img>
                        <v-card-text class="text-md-center">
                          <!-- <span class="subheading">{{item.name|truncate(20, '...') }}</span> -->
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </draggable>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-row class="ma-5" align="center" justify="end">
            <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
            <!-- <v-btn @click="submit()" color="primary">บันทึก</v-btn> -->
          </v-row>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      id: "",
      category: [],
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      company: "",
      itemsCompany: [],
      sku: "",
      productName: "",
      description: "",
      shortDesc: "",
      termDesc: "",
      stockAmt: "",
      unit: "",
      price: "",
      img: [],
      DataImage: [],
      product_image: [],
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      activeFlag: true,
      cost: null,
      deliveryFee: null,
      setupFee: null,
      isHilight: false,
      showIndex: null,
      companyName: [],
      // listDescription: [],
      productPriceList: [],
      productOptionList: [],

      // approveFlag: "",
      // itemsapproveFlag: [
      //   { text: "ยังไม่อนุมัติ", value: "0" },
      //   { text: "อนุมัติ", value: "1" },
      //   { text: "ตีกลับ", value: "2" },
      // ],
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  async created() {
    var productdata = JSON.parse(
      Decode.decode(localStorage.getItem("productdata"))
    );
    console.log("productdata", productdata);
    this.id = productdata.id;
    this.getOne();
    // this.itemsCategory = cateDropdown.data.data;
    // this.itemsType = typeDropdown.data.data;
    // console.log(this.itemsCategory, this.itemsType);
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    UploadImage() {
      for (let i = 0; i < this.DataImage.length; i++) {
        const element = this.DataImage[i];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          var resultReader = reader.result;
          var url = URL.createObjectURL(element);
          this.product_image.push({
            image_data: resultReader,
            url: url,
            name: this.DataImage[i].name,
            id: "-1",
          });
        };
      }
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext;
      const draggedElement = draggedContext;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    RemoveImage(index, item) {
      console.log(index, item);
      if (item.id !== "-1") {
        this.Remove_img.push({
          id: item.id,
        });
      }
      this.product_image.splice(index, 1);
    },
    async getOne() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/` + this.id
      );

      console.log("Product", response);
      const Product = response.data.data;
      this.id = Product.id;
      this.activeFlag = Product.activeFlag;
      // this.category = Product.categoryId;
      // this.type = Product.typeId;
      // this.brand = Product.brandId;
      // this.company = Product.company;
      if (Product.brandId) {
        this.brand = Product.brand;
      }
      // if (data.companyId) {
      //   this.company = data.company;
      // }
      if (Product.categoryId) {
        this.category = Product.category;
      }
      if (Product.typeId) {
        this.type = Product.type;
      }

      if (Product.productId) {
        this.product = Product.product;
      }
      this.sku = Product.sku;
      this.productName = Product.productName;
      this.description = Product.description;
      this.shortDesc = Product.shortDesc;
      this.termDesc = Product.termDesc;
      this.stockAmt = Product.stockAmt;
      this.unit = Product.unit;
      this.price = Product.price;
      // this.img = Product.img;
      this.cost = Product.cost;
      this.deliveryFee = Product.deliveryFee;
      this.setupFee = Product.setupFee;
      this.isHilight = Product.isHilight;
      this.showIndex = Product.showIndex;
      this.companyName = Product.companyName;
      this.productPriceList = Product.productPriceList;
      this.productOptionList = Product.productOptionList;
      // this.productType = Product.productType;
      // this.productNumber = Product.productNumber;
      // this.productName = Product.productName;
      // this.description = Product.description;
      // this.lineId = Product.lineId;
      // this.rentPrice = Product.rentPrice;
      // this.province = Product.province;
      // this.user = Product.user;
      this.product_image = Product.productImgs;
      this.showImage = Product.imgUrl;
      // this.picture_1 = Product.mainImg;
      // this.approveFlag = Product.approveFlag;
    },
    // UploadImage() {
    //   for (let i = 0; i < this.DataImage.length; i++) {
    //     const element = this.DataImage[i];
    //     const reader = new FileReader();
    //     reader.readAsDataURL(element);
    //     reader.onload = () => {
    //       var resultReader = reader.result;
    //       var url = URL.createObjectURL(element);
    //       this.product_image.push({
    //         image_data: resultReader,
    //         url: url,
    //         name: this.DataImage[i].name,
    //         id: "-1",
    //       });
    //     };
    //   }
    // },
    //  onPickFile() {
    //   document.getElementById("file_input").click();
    // },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext;
      const draggedElement = draggedContext;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    RemoveImage(index, item) {
      console.log(index, item);
      if (item.id !== "-1") {
        this.Remove_img.push({
          id: item.id,
        });
      }
      this.product_image.splice(index, 1);
    },
    cancel() {
      this.$router.push("ManageProduct");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        const productData = {
          categoryId: this.category,
          typeId: this.type,
          brandId: this.brand,
          sku: this.sku,
          productName: this.productName,
          description: this.description,
          shortDesc: this.shortDesc,
          termDesc: this.termDesc,
          unit: this.unit,
          stockAmt: this.stockAmt,
          price: this.price,
          activeFlag: this.activeFlag,
          cost: this.cost,
          deliveryFee: this.deliveryFee,
          setupFee: this.setupFee,
          imgUrl: this.pic1,
          imgUrlDesc: this.product_image,
          productPriceList: this.productPriceList,
          productOptionList: this.productOptionList,
        };
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userSPM"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        console.log("data", productData);
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/products/` + this.id,
          productData,
          auth
        );
        console.log("response product", response);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขข้อมูลสินค้าสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageProduct");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขข้อมูลสินค้าไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>